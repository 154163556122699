import React, { useState, useEffect } from 'react';
import checkboxoffinstallpopup from '../res_install/checkboxoffinstallpopup.png';
import checkboxoninstallpopup from '../res_install/checkboxoninstallpopup.png';
import installappinstallpopup from '../res_install/installappinstallpopup.png';
import camerainstallpopup from '../res_install/camerainstallpopup.png';
import microphoneinstallpopup from '../res_install/microphoneinstallpopup.png';
import { Oval } from 'react-loader-spinner'; // Импортируем лоадер из react-loader-spinner
import styled from 'styled-components';
import { logAmplitudeEvent } from '../amplitude';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const PwaInstall = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isAppChecked, setIsAppChecked] = useState(false);
  const [isCameraChecked, setIsCameraChecked] = useState(false);
  const [isMicrophoneChecked, setIsMicrophoneChecked] = useState(false);
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [showContinue, setShowContinue] = useState(false);

  const OpenPwaButton = styled.a`
    background-color: rgb(252, 217, 0);
    color: black;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin: 0px auto;
    display: block;
    text-align: center;
    text-decoration: none; /* Убираем подчеркивание */
  `;

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      const promptEvent = e as BeforeInstallPromptEvent;
      promptEvent.preventDefault();
      setDeferredPrompt(promptEvent);
      setIsPromptVisible(true);
    };

    const handleAppInstalled = () => {
      console.log('PWA установлено');
      setCountdown(15); // Запускаем отсчет 15 секунд
      startCountdown();  // Запускаем таймер сразу после установки PWA
      localStorage.setItem('pwainstall', 'true');
      
      setIsAppChecked(true);
      setIsPwaInstalled(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      await deferredPrompt.prompt();
      logAmplitudeEvent('e.2.0.6 - Permission to install PWA', { component: 'e.2.0.6 - Permission to install PWA' });
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        logAmplitudeEvent('e.2.0.6.1 - Start install PWA', { component: 'e.2.0.6.1 - Start install PWA' });
        setIsAppChecked(true);
        setIsPromptVisible(false);
        askMicrophonePermission();
      }
    }
  };

  const askMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsMicrophoneChecked(true);
      askCameraPermission();
      logAmplitudeEvent('e.2.0.7 - Access Microphone', { component: 'e.2.0.7 - Access Microphone' });
    } catch (error) {
      console.log('Микрофон: доступ отклонен');
      logAmplitudeEvent('e.2.0.7.1 - No access Microphone', { component: 'e.2.0.7.1 - No access Microphone' });
    }
  };

  const askCameraPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setIsCameraChecked(true);
      checkPermissionsAndStartCountdown();
      logAmplitudeEvent('e.2.0.8 - Access Camera', { component: 'e.2.0.8 - Access Camera' });
    } catch (error) {
      console.log('Камера: доступ отклонен');
      logAmplitudeEvent('e.2.0.8.1 - No access Camera', { component: 'e.2.0.8.1 - No access Camera' });
    }
  };

  const checkPermissionsAndStartCountdown = () => {
    if (isCameraChecked && isMicrophoneChecked) {
      console.log('Оба разрешения получены. Ожидание таймера.');
      logAmplitudeEvent('e.2.0.9 - All access', { component: 'e.2.0.9 - All access' });
    }
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown !== null && prevCountdown > 1) {
          return prevCountdown - 1;
        } else {
          clearInterval(countdownInterval);
          setShowContinue(true); // Показываем кнопку "Continue"
          logAmplitudeEvent('e.2.0.10 - PWA installed and all access', { component: 'e.2.0.10 - PWA installed and all access' });
          return null;
        }
      });
    }, 1000);
  };

  return (
    <div>
      {/* Чекбоксы для установки, камеры и микрофона */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <img src={installappinstallpopup} alt="Install App" style={{ width: '80%', height: 'auto' }} />
        <img
          src={isAppChecked ? checkboxoninstallpopup : checkboxoffinstallpopup}
          alt="Checkbox"
          style={{ width: '5%', height: 'auto' }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <img src={camerainstallpopup} alt="Camera" style={{ width: '80%', height: 'auto' }} />
        <img
          src={isCameraChecked ? checkboxoninstallpopup : checkboxoffinstallpopup}
          alt="Checkbox"
          style={{ width: '5%', height: 'auto' }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <img src={microphoneinstallpopup} alt="Microphone" style={{ width: '80%', height: 'auto' }} />
        <img
          src={isMicrophoneChecked ? checkboxoninstallpopup : checkboxoffinstallpopup}
          alt="Checkbox"
          style={{ width: '5%', height: 'auto' }}
        />
      </div>

      {/* Показываем лоадер "Wait..." только если оба разрешения выданы */}
      {isCameraChecked && isMicrophoneChecked && !showContinue ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
          <Oval color="white" height={50} width={50} /> {/* Белый лоадер без пульсации */}
        </div>
      ) : !showContinue && (
        <button
          onClick={handleInstallClick}
          style={{
            backgroundColor: 'rgb(252, 217, 0)',
            color: 'black',
            fontSize: '20px',
            fontWeight: 'bold',
            padding: '15px',
            border: 'none',
            borderRadius: '50px',
            cursor: 'pointer',
            margin: '0px auto',
            display: 'block',
            textAlign: 'center',
            textDecoration: 'none', // Убираем подчеркивание
            width: '100%'
          }}
        >
          Allow all
        </button>
      )}

      {/* Показываем кнопку "Continue" после окончания таймера */}
      {showContinue && (
        <OpenPwaButton
          href="https://golive.mobi/"
          target="_blank"
          onClick={() => logAmplitudeEvent('e.2.0.11 - Redirect to PWA', { component: 'e.2.0.11 - Redirect to PWA' })}
        >
          Continue
        </OpenPwaButton>
      )}
    </div>
  );
};

export default PwaInstall;
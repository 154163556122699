// src/amplitude.ts
import * as amplitude from '@amplitude/analytics-browser';

// Инициализация Amplitude
export const initializeAmplitude = (): void => {
  amplitude.init('2d648e96fd7f6ee923bde0c0bb0684b2', undefined, {
    defaultTracking: {
      sessions: true,   // Включает автоматическое отслеживание сессий
      pageViews: true,  // Автоматическое отслеживание просмотров страниц
    },
  });
};

// Функция для отправки событий
export const logAmplitudeEvent = (eventName: string, eventData?: Record<string, any>): void => {
  amplitude.track(eventName, eventData);
};
import { useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { logAmplitudeEvent } from '../amplitude';

interface PaymentModalProps {
  label: string; // Название для отображения в платеже (например, "150 Coins")
  amount: number; // Сумма в центах для платежа
  payment_charge_id: number; // ID платежного плана
  onPaymentSuccess: () => void; // Пропс для передачи функции при успешной оплате
}

const PaymentModal: React.FC<PaymentModalProps> = ({ label, amount, payment_charge_id, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    // Callback для обработки изменений в DOM
    const mutationCallback = (mutationsList: MutationRecord[]) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // Ищем элемент с классом .GooglePayButton
          const googlePayButton = document.querySelector('.GooglePayButton');
          if (googlePayButton) {
            (googlePayButton as HTMLElement).style.maxHeight = '128px'; // Принудительно устанавливаем max-height
            observer.disconnect(); // Как только элемент найден, отключаем MutationObserver
          }
        }
      }
    };

    // Настраиваем MutationObserver для отслеживания изменений в body
    const observer = new MutationObserver(mutationCallback);
    observer.observe(document.body, { childList: true, subtree: true });

    // Очищаем observer при размонтировании компонента
    return () => observer.disconnect();
  }, []); 

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: label, // Название пакета монет
          amount: amount, // Используем переданный amount
        },
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(true);
        }
      });

      pr.on('paymentmethod', async (event: any) => {
        try {
          const userId = Cookies.get('userId');

          // Отправляем запрос на ваш сервер для создания Payment Intent
          const paymentResponse = await fetch('https://golive.mobi/api/web/create-payment-intent/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Token 978f3467a8cbdf3451d1a032d9e03cd8e376e32d',
            },
            body: JSON.stringify({
              id: userId,
              payment_method_id: event.paymentMethod.id, // Используем ID, который вернёт Stripe
              payment_charge_id: payment_charge_id, // Динамический ID платежа
              payment_plan_id: null,
            }),
          });

          const paymentData = await paymentResponse.json();

          if (!paymentData.clientSecret) {
            throw new Error('Не удалось получить client_secret');
          }

          // Подтверждение платежа на клиентской стороне с использованием client_secret
          const { paymentIntent, error } = await stripe.confirmCardPayment(paymentData.clientSecret, {
            payment_method: event.paymentMethod.id, // Используем payment_method_id
          });

          if (error) {
            event.complete('fail');
            console.error('Ошибка подтверждения платежа:', error);
            logAmplitudeEvent('e.4.0.1 - Error paying', { component: 'e.4.0.1 - Error paying' });
          } else {
            event.complete('success');
            logAmplitudeEvent('e.4.0.2 - Success paying', { component: 'e.4.0.2 - Success paying' });
            handleSuccessfulPayment();
          }
        } catch (error) {
          console.error('Ошибка при обработке платежа:', error);
          logAmplitudeEvent('e.4.0.1 - Error paying', { component: 'e.4.0.1 - Error paying' });
          event.complete('fail');
        }
      });
    }
  }, [stripe, amount, label, payment_charge_id]); // Обновляем useEffect при изменении пропсов

  const handleSuccessfulPayment = async () => {
    const userId = Cookies.get('userId');
    
    console.log('Payment successful for user', userId);

    // Конвертируем сумму из центов в доллары
    const amountInDollars = amount / 100;

    // Отправляем событие покупки в Facebook Pixel
    if (window.fbq) {
      window.fbq('track', 'Purchase', {
        value: amountInDollars.toFixed(2), // Сумма в долларах
        currency: 'USD',
      });
    }

    // Отправляем событие покупки в Google Ads
    if (window.gtag) {
      window.gtag('event', 'purchase', {
        send_to: 'AW-16532794171', // Ваш Google Ads ID
        value: amountInDollars, // Сумма в долларах
        currency: 'USD',
        transaction_id: userId // Можно использовать ID пользователя или любой другой уникальный ID транзакции
      });
    }

    // Вызов функции при успешной оплате
    onPaymentSuccess(); // Открываем новое модальное окно в RandomCall или обрабатываем результат

    // Проверка на существование BPixelJS и вызов конверсии
    if (typeof window.BPixelJS !== 'undefined') {
      window.BPixelJS.conversion({
        url: 'https://deus-team.com/click',
      });
    } else {
      console.error("BPixelJS не загружен");
    }
  };

  // Эмуляция вызова BPixelJS через скрытую кнопку
  const emulateBPixelJS = () => {
    if (typeof window.BPixelJS !== 'undefined') {
      window.BPixelJS.conversion({
        url: 'https://deus-team.com/click',
      });
      console.log("BPixelJS конверсия вызвана вручную.");
    } else {
      console.error("BPixelJS не загружен для эмуляции.");
    }
  };

  return (
    <>
      {canMakePayment && paymentRequest && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                type: 'default',
                theme: 'dark',
                height: '64px'
              },
            },
          }}
        />
      )}

      {/* Скрытая кнопка для эмуляции вызова BPixelJS */}
      <button 
        style={{ width: '1px', height: '1px', display: 'none' }} 
        onClick={emulateBPixelJS}>
        Эмуляция BPixelJS
      </button>
    </>
  );
};

export default PaymentModal;